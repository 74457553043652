import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { breakpoints, colors } from '../../constants';
import { DesktopNav } from './desktop-nav';
import { MobileNav } from './mobile-nav';

const Nav = ({ itemsWrapperRef, navItemsCount, ...rest }) => {
  return (
    <NavWrapper ref={itemsWrapperRef}>
      <DesktopNav navItemsCount={navItemsCount} {...rest} />
      <MobileNav {...rest} />
    </NavWrapper>
  );
};

const NavWrapper = styled.div`
  display: flex;
  height: 4rem;
  overflow-y: hidden;
  scrollbar-width: none;
  border-bottom: 1px solid ${colors.grey100};

  &::-webkit-scrollbar {
    display: none;
  }

  @media (min-width: ${breakpoints.lg}) {
    border-bottom: none;
    overflow-y: inherit;
  }

  @media (min-width: ${breakpoints.xl}) {
    height: 3.5rem;
  }

  @media (min-width: ${breakpoints.xxl}) {
    height: 2.5rem;
  }
`;

Nav.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  itemsWrapperRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({ current: PropTypes.any })]).isRequired,
  navItemsCount: PropTypes.number.isRequired,
};

const ObservedNav = observer(Nav);

export { ObservedNav as Nav };
