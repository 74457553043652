import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { breakpoints } from '../../constants';
import { Pill } from '../pill';
import { MoreSelect } from './more-select';
import { navDefaultProps, navPropTypes } from './nav-prop-types';
import { Nav, NavItem, NavLink } from './styles';

const DesktopNav = ({ selectedSectionId, handlePageScroll, navItems, navItemsCount, itemRefs, handleItemClick }) => {
  const [isOpen, setIsOpen] = useState(false);
  const selectRef = useRef(null);

  const handleClick = itemId => {
    setIsOpen(!isOpen);

    if (itemId) {
      handleItemClick(itemId);
    }
  };

  const handleClickOutside = event => {
    if (selectRef.current && !selectRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  });

  return (
    <StyledNav onScroll={handlePageScroll} gap={1}>
      {navItems.slice(0, navItemsCount).map(item => (
        <Pill
          key={item.id}
          isActive={selectedSectionId === item.id}
          initialText={item.label}
          outline={false}
          onClick={() => handleItemClick(item.id)}
          ref={itemRefs[item.id]}
        />
      ))}
      {navItems.length > navItemsCount && (
        <MoreSelect
          isOpen={isOpen}
          navItems={navItems.slice(navItemsCount)}
          onClick={handleClick}
          selectRef={selectRef}
          selectedSectionId={selectedSectionId}
        >
          {navItems.slice(navItemsCount).map(
            item =>
              item && (
                <NavItem pb=".25rem" pt="1.25rem" mx="1rem" active={selectedSectionId === item.id} key={item.id}>
                  <NavLink onClick={() => handleClick(item.id)}>{item.label}</NavLink>
                </NavItem>
              )
          )}
        </MoreSelect>
      )}
    </StyledNav>
  );
};

const StyledNav = styled(Nav)`
  display: none;

  ${NavItem}:first-child {
    margin-left: 0;
  }

  @media (min-width: ${breakpoints.lg}) {
    align-items: center;
    display: flex;
    justify-content: flex-start;
  }
`;

DesktopNav.propTypes = { ...navPropTypes, navItemsCount: PropTypes.number.isRequired };
DesktopNav.defaultProps = navDefaultProps;

export { DesktopNav };
