import {
  find,
  includes,
  invoke,
  isEmpty,
  isNil,
  isString,
  keyBy,
  lastIndexOf,
  map,
  mapValues,
  maxBy,
  throttle,
} from 'lodash';
import { computed, makeObservable } from 'mobx';
import { observer } from 'mobx-react';
import { withRouter } from 'next/router';
import PropTypes from 'prop-types';
import React, { PureComponent, createRef } from 'react';
import { Events, scroller } from 'react-scroll';
import styled from 'styled-components';

import { GroupDetailsButton } from '@components/group-details-button';
import { ANALYTICS_EVENTS } from '@nandosaus/constants';
import { analytics } from '../../analytics';
import { breakpoints, colors } from '../../constants';
import { createNavIds } from '../../utils/createNavIds';
import { DietaryFilter } from '../dietary-filter';
import { Box, Col, Container, Row } from '../grid';
import { AddedToOrderToast } from './added-to-order-toast';
import { CartButton } from './cart-button';
import { Nav } from './nav';
import { centerElementInScrollContainer, getElementVisibleHeight, giveSectionFocus } from './util';

export const LG_PLUS_BREAKPOINT = '1120px';
export const XL_PLUS_BREAKPOINT = '1250px';

export const NAV_ITEMS_COUNT = {
  LG: 3,
  LGPlus: 4,
  XLPlus: 5,
};

class InPageNav extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      isScrolling: false,
      scrollListener: null,
      isMouseDown: false,
      startX: null,
      scrollLeft: null,
      mouseMoveListener: null,
      selectedSectionId: null,
      isItemClick: false,
    };

    makeObservable(this, {
      navItems: computed,
    });

    this.inPageNavRef = createRef();
    this.itemsWrapperRef = createRef();
    this.navMouseDown = this.navMouseDown.bind(this);
    this.navMouseUp = this.navMouseUp.bind(this);
    this.handleNavScroll = this.handleNavScroll.bind(this);
    this.handleScrollBegin = this.handleScrollBegin.bind(this);
    this.handleScrollEnd = this.handleScrollEnd.bind(this);
    this.handlePageScroll = this.handlePageScroll.bind(this);
    this.scrollToSection = this.scrollToSection.bind(this);
    this.selectItem = this.selectItem.bind(this);
    this.itemRefs = mapValues(
      keyBy(
        this.navItems.filter(({ id }) => isString(id) && !isEmpty(id)),
        'id'
      ),
      createRef
    );
  }

  componentDidMount() {
    this.itemsWrapperRef.current.addEventListener('mousedown', this.navMouseDown, false);
    this.itemsWrapperRef.current.addEventListener('mouseup', this.navMouseUp, false);

    const mouseMoveListener = throttle(this.handleNavScroll, 20);
    invoke(this.itemsWrapperRef.current, 'addEventListener', 'mousemove', mouseMoveListener);

    const scrollListener = throttle(this.handlePageScroll, 150);
    invoke(window, 'addEventListener', 'scroll', scrollListener);

    this.setState({
      mouseMoveListener,
      scrollListener,
    });

    Events.scrollEvent.register('begin', this.handleScrollBegin);
    Events.scrollEvent.register('end', this.handleScrollEnd);
    this.performInitialCategoryScroll();
  }

  componentDidUpdate(prevProps) {
    const { router } = this.props;
    if (prevProps.router !== router) {
      this.performInitialCategoryScroll();
    }
  }

  componentWillUnmount() {
    const { scrollListener, mouseMoveListener } = this.state;
    invoke(window, 'removeEventListener', 'scroll', scrollListener);
    invoke(this.itemsWrapperRef.current, 'removeEventListener', 'mousemove', mouseMoveListener);

    this.itemsWrapperRef.current.removeEventListener('mousedown', this.navMouseDown, false);
    this.itemsWrapperRef.current.removeEventListener('mouseup', this.navMouseUp, false);

    Events.scrollEvent.remove('begin');
    Events.scrollEvent.remove('end');
  }

  handleNavScroll(e) {
    const { isMouseDown, startX, scrollLeft } = this.state;

    if (!isMouseDown) return;

    e.preventDefault();

    const x = e.pageX - this.itemsWrapperRef.current.offsetLeft;
    const walk = x - startX;
    this.itemsWrapperRef.current.scrollLeft = scrollLeft - walk;
  }

  handleScrollBegin() {
    this.setState({
      isScrolling: true,
    });
  }

  handleScrollEnd() {
    this.setState({
      isScrolling: false,
    });

    const { selectedSectionId } = this.state;
    giveSectionFocus(selectedSectionId);
  }

  handlePageScroll() {
    const { isScrolling, selectedSectionId, isItemClick } = this.state;

    const mostVisibleId = maxBy(map(this.navItems, 'id'), getElementVisibleHeight);

    if (mostVisibleId !== selectedSectionId && !isScrolling && !isItemClick) {
      this.selectItem(mostVisibleId);
    }

    if (!isScrolling) {
      this.setState({ isItemClick: false });
    }
  }

  get navItems() {
    const { sections } = this.props;
    return createNavIds(sections);
  }

  navMouseUp() {
    this.setState({
      isMouseDown: false,
    });
  }

  navMouseDown(e) {
    this.setState({
      isMouseDown: true,
      startX: e.pageX - this.itemsWrapperRef.current.offsetLeft,
      scrollLeft: this.itemsWrapperRef.current.scrollLeft,
    });
  }

  selectItem(id) {
    if (isNil(this.itemsWrapperRef) || isNil(this.itemRefs[id])) {
      return;
    }

    const wrapper = this.itemsWrapperRef.current;
    const item = this.itemRefs[id].current;

    if (!wrapper || !item) {
      return;
    }

    centerElementInScrollContainer(wrapper, item);

    this.setState({
      selectedSectionId: id,
    });
  }

  scrollToSection(sectionId) {
    const section = find(this.navItems, { id: sectionId });
    if (!section) return;

    scroller.scrollTo(sectionId, {
      duration: 800,
      offset: -(this.inPageNavRef.current.clientHeight + 40),
      smooth: true,
    });

    // make category look like this: 'Popular Menus' instead of `popular-menus`
    if (!isEmpty(section.label)) {
      analytics.track(ANALYTICS_EVENTS.MENU_CATEGORY_NAVIGATION_CLICKED, {
        category: section.label,
      });
    }

    this.setState({ isItemClick: true });
    this.selectItem(sectionId);
  }

  performInitialCategoryScroll() {
    const { router } = this.props;
    const initialSection = router?.asPath.substring(lastIndexOf(router?.asPath, '#'));
    const sectionIds = map(this.navItems, 'id');
    if (includes(sectionIds, initialSection)) {
      this.scrollToSection(initialSection);
    }
  }

  render() {
    const { selectedSectionId } = this.state;
    const {
      cartOnClick,
      quantity,
      showDietaryPreferences,
      sections,
      showGroupInfoButton,
      groupInfoOnClick,
      navItemsCount,
    } = this.props;

    return (
      <Affix>
        <Container>
          <Box ref={this.inPageNavRef} mx={{ _: '-1.5rem', md: -2, lg: 0 }}>
            <Row flexWrap={{ _: 'wrap', lg: 'nowrap' }} justifyContent="space-between">
              <StyledCol
                width={{ _: 12 / 12, lg: 9 / 12 }}
                offset={showDietaryPreferences ? [] : [0 / 12, 0 / 12, 0 / 12, 1 / 12]}
              >
                <Nav
                  handleItemClick={this.scrollToSection}
                  handlePageScroll={this.handlePageScroll}
                  itemRefs={this.itemRefs}
                  itemsWrapperRef={this.itemsWrapperRef}
                  navItems={createNavIds(sections)}
                  navItemsCount={navItemsCount}
                  selectedSectionId={selectedSectionId}
                />
              </StyledCol>
              {showGroupInfoButton && (
                <StyledCol order={{ _: 2, lg: 'unset' }} width={{ _: 6 / 12, lg: 'fit-content' }} display="flex">
                  <Box alignItems="center" justifyContent="center" display="flex" position="relative">
                    <GroupDetailsButton onClick={groupInfoOnClick} />
                  </Box>
                </StyledCol>
              )}
              {cartOnClick && (
                <StyledCol width="fit-content" display={{ xs: 'none', lg: 'flex' }}>
                  <Box alignItems="center" position="relative">
                    <CartButton onClick={cartOnClick} quantity={quantity} />
                    <AddedToOrderToast />
                  </Box>
                </StyledCol>
              )}
              {showDietaryPreferences && (
                <StyledCol order={{ lg: -1 }} width={{ _: showGroupInfoButton ? 6 / 12 : 12 / 12, lg: 'fit-content' }}>
                  <Box
                    display="flex"
                    py={{ _: '12px', lg: 0 }}
                    alignItems="center"
                    justifyContent="center"
                    height="100%"
                  >
                    <DietaryFilter />
                  </Box>
                </StyledCol>
              )}
            </Row>
          </Box>
        </Container>
      </Affix>
    );
  }
}

const Affix = styled.div`
  margin-top: -4px;
  background-color: ${colors.white};
  overflow: hidden;
  position: sticky;
  top: 0;
  z-index: 20;
  border-bottom: 1px solid ${colors.grey100};

  @media (min-width: ${breakpoints.lg}) {
    overflow: visible;
  }
`;

const StyledCol = styled(Col)`
  flex-direction: column;
  justify-content: center;
  overflow-y: hidden;

  @media (min-width: ${breakpoints.md}) {
    overflow-y: visible;
  }
`;

InPageNav.propTypes = {
  cartOnClick: PropTypes.func,
  quantity: PropTypes.number,
  router: PropTypes.shape({
    asPath: PropTypes.string,
  }).isRequired,
  sections: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
    }).isRequired
  ).isRequired,
  showDietaryPreferences: PropTypes.bool,
  showGroupInfoButton: PropTypes.bool,
  groupInfoOnClick: PropTypes.func,
  navItemsCount: PropTypes.number.isRequired,
};

InPageNav.defaultProps = {
  cartOnClick: null,
  quantity: 0,
  showDietaryPreferences: false,
  showGroupInfoButton: false,
  groupInfoOnClick: () => {},
};

const InPageNavWithRouter = observer(withRouter(InPageNav));
export { InPageNavWithRouter as InPageNav };
