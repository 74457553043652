import {
  ANALYTICS_EVENTS,
  MARKET,
  MENU_WELCOME_DISMISSED_KEY,
  SAVED_DIETARY_PREFERENCES_KEY,
} from '@nandosaus/constants';
import { get, isEmpty, map, isNil, isObject, toString } from 'lodash';
import { observer } from 'mobx-react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useRootStore } from '@nandosaus/state-management';
import { useRouter } from 'next/router';
import dynamic from 'next/dynamic';
import Head from 'next/head';
import PropTypes from 'prop-types';

import { analytics } from '@analytics';
import { Col, Container, Row } from '@components/grid';
import { CartPreview } from '@components/cart-preview';
import { DefaultState } from '@components/default';
import { Drawer } from '@components/drawer';
import { ErrorBoundary } from '@components/error-boundary';
import { fetchCMSContent } from '@utils/fetch-cms-content';
import { floodlightPageLoadEventPropTypes } from '@prop-types';
import { HolidaySurchargeAlert } from '@components/holiday-surcharge-alert';
import { HalalAlert } from '@components/halal-alert';
import { InPageNav, NAV_ITEMS_COUNT, LG_PLUS_BREAKPOINT, XL_PLUS_BREAKPOINT } from '@components/in-page-nav';
import { Layout } from '@components/layout';
import { localStorage } from '@utils/local-storage';
import { MenuDisclaimer } from '@components/menu-disclaimer';
import { MenuItemCard } from '@components/card';
import { OrderSettingsBanner } from '@components/order-settings-banner';
import { OrderSettingsModal } from '@components/order-settings-modal';
import { ProductCategoryList } from '@components/product-category-list';
import { ProductDetailModal } from '@components/product-detail-modal';
import { seoPropDefaults, seoPropTypes } from '@utils/extract-seo-meta-from-entry';
import { SkeletonLoader } from '@components/skeleton-loader';
import { StockShortageAlert } from '@components/stock-shortage-alert';
import { useCookie } from '@hooks/use-cookie';
import { useFeature } from '@hooks/use-feature';
import { useIsClient } from '@hooks/use-is-client';
import { useMarket } from '@hooks/use-market';
import { withAppProps } from '@utils/with-app-props';
import { CallToAction } from '@components/call-to-action';
import { RecommendationEngineModal } from '@components/recommendation-engine-modal';
import { useCartPersistence } from '@hooks/use-cart-persistence';
import { useDineInPreSet } from '@hooks/use-dine-in-pre-set';
import { PopularThisWeek } from '@components/welcome-page-popular-this-week';
import { PromotionList } from '@components/offer/promotion-list';
import { TabOrderShareModal } from '@components/tab-order-share-modal';
import { useMediaQuery } from 'react-responsive';
import { useShouldEnableRecommendationEngine } from '@hooks/use-should-enable-recommendation-engine';
import { useCartVisibility } from '@hooks/use-cart-visibility';
import { useUpsellModalHandler } from '@hooks/use-upsell-modal-handler';
import { useBrazeUpsellContent } from '@hooks/use-braze-upsell-content';
import { UpsellModal } from '@components/upsell-modal';

import { SearchBar } from '@components/search-bar';
import { useSearch } from '@hooks/use-search';
import Error from '../_error';

const MenuWelcomeModal = dynamic(() =>
  import('@components/menu-welcome-modal').then(module => module.MenuWelcomeModal)
);
const MenuPromotionModal = dynamic(() =>
  import('@components/menu-promotion-modal').then(module => module.MenuPromotionModal)
);

const useBrazePromotionContent = ({ promotionProduct, heading, subheading, body, cta, ctaDismiss }) => {
  const { BrazeStore } = useRootStore();
  const { specialOfferCard } = BrazeStore;
  if (!specialOfferCard || !specialOfferCard.promotedProduct) {
    return {
      promotionProduct,
      heading,
      subheading,
      body,
      cta,
      ctaDismiss,
    };
  }
  const product = specialOfferCard.promotedProduct;
  const brazePromotionProduct = {
    name: product.name,
    description: `${product.name} from Braze`,
    plu: specialOfferCard.plu,
    enabled: true,
  };

  return {
    promotionProduct: brazePromotionProduct,
    heading: specialOfferCard?.heading,
    subheading: specialOfferCard?.subheading,
    body: specialOfferCard?.body,
    cta: specialOfferCard?.cta,
    ctaDismiss: specialOfferCard?.ctaDismiss,
    brazeLogClick: specialOfferCard.brazeLogClick,
    brazeLogImpression: specialOfferCard.brazeLogImpression,
  };
};

const Menu = ({ metaContent, floodlightPageLoadEvent }) => {
  // hooks
  const searchFlags = useFeature('search');
  const showDietaryPreferences = useFeature('dietary-preferences');
  const router = useRouter();
  const market = useMarket();
  const {
    BrazeStore,
    CartStore,
    DietaryPreferencesStore,
    MemberStore,
    MenuStore,
    OrderStore,
    OrderingContextStore,
    ProductDetailsState,
    RestaurantStore,
    GroupOrderStore,
    DeliveryStore,
  } = useRootStore();

  const shouldEnableRecommendationEngine = useShouldEnableRecommendationEngine();

  const isClient = useIsClient();
  const { hasReordered } = CartStore;

  // mobx state
  const { itemCount, orderType: cartStoreOrderType } = CartStore;
  const { setSavedDietaryPreferences } = DietaryPreferencesStore;
  const { error, menu, loadMenu, fetchAllergenPDF } = MenuStore;
  const { brazeMergedCategories, brazeMergedCategoryNames } = BrazeStore;
  const brazeMergedNavSections = brazeMergedCategoryNames.map(name => ({ id: name }));
  const menuPromotionProduct = get(menu, 'menuPromotion');

  const {
    isUpsellModalEnabled,
    isUpsellModalOpen,
    hasUpsellModalShown,
    closeUpsellModal,
    openUpsellModal,
  } = useUpsellModalHandler({ modalOpeningDelay: 500 });

  const {
    content: upsellContent,
    recommendedProducts: upsellRecommendedProducts,
    newProductIds: upsellNewProductIds,
    brazeLogImpression: upsellBrazeLogImpression,
    brazeLogClick: upsellBrazeLogClick,
  } = useBrazeUpsellContent();

  const specialPromotionContent = {
    promotionProduct: menuPromotionProduct,
    heading: `Can't decide?`,
    subheading: null,
    body: null,
    cta: 'add item',
    ctaDismiss: 'No, thanks',
  };

  const {
    promotionProduct,
    heading: promotionHeading,
    subheading: promotionSubheading,
    body: promotionBody,
    cta: promotionCta,
    ctaDismiss: promotionCtaDismiss,
    brazeLogClick: promotionBrazeLogClick,
    brazeLogImpression: promotionBrazeLogImpression,
  } = useBrazePromotionContent(specialPromotionContent);

  const chosenDietaryPreferences = get(DietaryPreferencesStore, 'dietaryPreferencesList');
  const isLoading = !isClient || MenuStore.loading || !RestaurantStore.loaded || MemberStore.loading;

  // search
  const showSearch = Object.keys(searchFlags)?.includes(CartStore.orderType)
    ? searchFlags[CartStore.orderType]
    : searchFlags.DEFAULT;

  const { categoriesWithSearch, searchValue, setSearchValue, hasSearchValue } = useSearch({
    categories: brazeMergedCategories,
  });
  const hasSearchResults = !!categoriesWithSearch[0]?.products?.length;

  // local state
  const [shouldShowOrderSettingsModal, setShouldShowOrderSettingsModal] = useState(false);
  const [productModalOpen, setProductModalOpen] = useState(false);
  const [recommendationEngineModalOpen, setRecommendationEngineModalOpen] = useState(false);
  const [isShowingTabOrderShareModal, setIsShowingTabOrderShareModal] = useState(false);
  const [visibleCart, setVisibleCart] = useCartVisibility(!isNil(router.query.showCart));
  const [hasDismissedWelcomeModal, setHasDismissedWelcomeModal] = useCookie(MENU_WELCOME_DISMISSED_KEY);

  const showStockShortageAlert = get(menu, 'showStockShortageAlert');
  const showHolidaySurchargeAlert = CartStore.restaurant?.isHoliday ?? false;

  const halalAlertData = useFeature('halal-alert');
  const restaurantIdsShowingHalalAlert = map(halalAlertData?.restaurantIds, toString);
  const showHalalAlert = restaurantIdsShowingHalalAlert.includes(CartStore.restaurantId);

  const { isRestaurantPreSet, applyPreSet } = useDineInPreSet();

  const showWelcomeModal = isRestaurantPreSet && !hasDismissedWelcomeModal;
  const showOrderSettingsModal = !showWelcomeModal && shouldShowOrderSettingsModal;
  const showPromotionModal =
    (!showWelcomeModal || !!hasDismissedWelcomeModal) && !showOrderSettingsModal && isEmpty(chosenDietaryPreferences);

  // effects
  useEffect(() => {
    if (isRestaurantPreSet) {
      router.push('/welcome');
    }
  }, [isRestaurantPreSet, router]);

  const { loadPreviousCart } = useCartPersistence();
  useEffect(() => {
    const fetchSavedDietaryPreferences = async () => {
      const savedDietaryPreferences = await localStorage.getItem(SAVED_DIETARY_PREFERENCES_KEY);

      if (isObject(savedDietaryPreferences)) {
        setSavedDietaryPreferences(savedDietaryPreferences);
      }
    };

    (async () => {
      await applyPreSet();

      if (!OrderingContextStore.isPreset && router.isReady) {
        await loadPreviousCart();
      }
      fetchSavedDietaryPreferences();

      setShouldShowOrderSettingsModal(!OrderingContextStore.isValid);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cartStoreOrderType, setSavedDietaryPreferences, router.isReady, loadPreviousCart, applyPreSet]);

  useEffect(() => {
    loadMenu();
    fetchAllergenPDF();
  }, [fetchAllergenPDF, loadMenu]);

  useEffect(() => {
    const cartClosedButStillOpenInQuery = !visibleCart && !isNil(router.query.showCart);
    if (cartClosedButStillOpenInQuery) {
      router.push({ query: '' }, undefined, { shallow: true });
    }
  }, [router, visibleCart]);

  useEffect(() => {
    if (hasReordered) {
      analytics.track(ANALYTICS_EVENTS.USER_VIEWED_MENU_AFTER_REORDER);
    }
  }, [hasReordered]);

  useEffect(() => {
    if (router.query.recommendationEngine) {
      setRecommendationEngineModalOpen(true);
    }
  }, [router.query.recommendationEngine]);

  // functions
  const viewProduct = useCallback(
    (product, analyticsCategory) => {
      ProductDetailsState.viewProduct({ product, analyticsCategory });
      setProductModalOpen(true);
    },
    [ProductDetailsState, setProductModalOpen]
  );

  const handleCartOnClick = () => {
    setVisibleCart(true);
    if (isUpsellModalEnabled && !hasUpsellModalShown) {
      openUpsellModal();
    }
  };

  const reloadMenu = () => MenuStore.loadMenu();

  const isViewportLGPlus = useMediaQuery({ query: `(min-width: ${LG_PLUS_BREAKPOINT})` });
  const isViewportXl = useMediaQuery({ query: `(min-width: ${XL_PLUS_BREAKPOINT})` });

  const navItemsCount = useMemo(() => {
    let baseNavItems = NAV_ITEMS_COUNT.LG;
    baseNavItems = isViewportLGPlus ? NAV_ITEMS_COUNT.LGPlus : baseNavItems;
    baseNavItems = isViewportXl ? NAV_ITEMS_COUNT.XLPlus : baseNavItems;

    // If we are showing the view group order button, we need fewer nav items to compensate.
    return GroupOrderStore.isInGroup ? baseNavItems - 1 : baseNavItems;
  }, [GroupOrderStore.isInGroup, isViewportLGPlus, isViewportXl]);

  // if a menu loading error occurs on server side only, we can get in a bad state where the server renders <Error /> but the client doesn't,
  // and the two get spliced together. Hence, we only show this error on client. See https://app.clickup.com/t/39gr2pp
  if (isClient && error) {
    return <Error showRefreshButton reload={reloadMenu} />;
  }

  return (
    <Layout
      floodlightPageLoadEvent={floodlightPageLoadEvent}
      hasBackgroundPattern
      metaContent={metaContent}
      onScreenButtonProps={{
        itemCount,
        onClick: handleCartOnClick,
        price: OrderStore.subTotal.formattedPrice,
      }}
      title={`Menu | Nando’s ${market === MARKET.AUSTRALIA ? 'Australia' : 'New Zealand'}`}
    >
      <Head>
        <meta
          name="description"
          content="Browse our full menu selection of PERi-PERi Grilled Chicken, Burgers, Wraps, Pitas, Vegetarian & Kids options, desserts, drinks, and mouth-watering sides."
        />
      </Head>
      <ErrorBoundary>
        <OrderSettingsBanner
          isRestaurantPreSet={isRestaurantPreSet}
          onClick={() => {
            setShouldShowOrderSettingsModal(true);
          }}
        />
      </ErrorBoundary>
      {isLoading ? (
        <SkeletonLoader height="2rem" />
      ) : (
        <>
          <InPageNav
            cartOnClick={handleCartOnClick}
            groupInfoOnClick={() => setIsShowingTabOrderShareModal(true)}
            navItemsCount={navItemsCount}
            quantity={itemCount}
            sections={brazeMergedNavSections}
            showDietaryPreferences={showDietaryPreferences}
            showGroupInfoButton={GroupOrderStore.isInGroup}
          />
          {showSearch && (
            <SearchBar onSearchChange={setSearchValue} searchValue={searchValue} width={{ _: 12 / 12, lg: 6 / 12 }} />
          )}
        </>
      )}
      {showStockShortageAlert && <StockShortageAlert page="menu" />}

      {showHolidaySurchargeAlert && <HolidaySurchargeAlert />}

      {showHalalAlert && <HalalAlert />}
      <ErrorBoundary>
        {!isLoading && (
          <>
            {MemberStore.isSignedIn && !GroupOrderStore.isGroupInvitee && !hasSearchValue && (
              <Container mt={showSearch ? { xs: 4, lg: 2 } : 4}>
                <PromotionList analyticsLocation="Menu" />
              </Container>
            )}
            {shouldEnableRecommendationEngine && !GroupOrderStore.isInGroup && !hasSearchValue && (
              <Container>
                <CallToAction
                  title="Need a little help?"
                  subtitle="Answer 3 easy questions and we’ll recommend an order for you and your guests"
                  buttonLabel="Help me choose"
                  backgroundImage="/static/images/menu/recommendation_engine_cta.png"
                  onClick={() => setRecommendationEngineModalOpen(true)}
                />
              </Container>
            )}

            {hasSearchValue && !hasSearchResults && (
              <Container>
                <Row>
                  <Col textAlign="center" width={{ _: 10 / 12, md: 6 / 12 }} offset={[1 / 12, 1 / 12, 3 / 12]}>
                    <DefaultState
                      subtitle={`No results for "${searchValue}". Maybe check your spelling, search for something else, or just browse category tabs above`}
                      title="Oops"
                    />
                  </Col>
                </Row>
              </Container>
            )}
            {isEmpty(chosenDietaryPreferences) && (!hasSearchValue || !hasSearchResults) && (
              <Container mt={MemberStore.isSignedIn || showSearch ? 2 : 4}>
                <PopularThisWeek />
              </Container>
            )}
            {(!hasSearchValue || hasSearchResults) && (
              <ProductCategoryList
                categories={categoriesWithSearch}
                CardComponent={MenuItemCard}
                viewProduct={viewProduct}
                isDelivery={CartStore.isDelivery}
                isDeliveryDiscountActive={DeliveryStore.isDeliveryDiscountActive}
              />
            )}
            <MenuDisclaimer />
          </>
        )}
      </ErrorBoundary>
      <Drawer open={visibleCart} anchor="right" onClose={() => setVisibleCart(false)}>
        <CartPreview
          hidePreview={() => setVisibleCart(false)}
          onSettingsClick={() => setShouldShowOrderSettingsModal(true)}
        />
      </Drawer>
      {shouldEnableRecommendationEngine && (
        <ErrorBoundary>
          <RecommendationEngineModal
            modalOpen={recommendationEngineModalOpen}
            handleModalClose={() => setRecommendationEngineModalOpen(false)}
            setVisibleCart={setVisibleCart}
          />
        </ErrorBoundary>
      )}
      {productModalOpen && (
        <ProductDetailModal modalOpen={productModalOpen} handleModalClose={() => setProductModalOpen(false)} />
      )}
      {showWelcomeModal ? (
        <MenuWelcomeModal
          onClose={() => {
            setHasDismissedWelcomeModal(true, { expires: 1 });
          }}
        />
      ) : null}
      {!hasUpsellModalShown && (
        <UpsellModal
          modalOpen={isUpsellModalOpen}
          handleModalClose={closeUpsellModal}
          recommendedProducts={upsellRecommendedProducts}
          newProductIds={upsellNewProductIds}
          content={upsellContent}
          brazeLogImpression={upsellBrazeLogImpression}
          brazeLogClick={upsellBrazeLogClick}
        />
      )}
      {showOrderSettingsModal && <OrderSettingsModal onClose={() => setShouldShowOrderSettingsModal(false)} />}
      {isLoading ? null : (
        <>
          {showPromotionModal && !isEmpty(promotionProduct) ? (
            <MenuPromotionModal
              brazeLogImpression={promotionBrazeLogImpression}
              brazeLogClick={promotionBrazeLogClick}
              viewProduct={viewProduct}
              menuPromotion={promotionProduct}
              content={{
                body: promotionBody,
                cta: promotionCta,
                ctaDismiss: promotionCtaDismiss,
                heading: promotionHeading,
                subheading: promotionSubheading,
              }}
            />
          ) : null}
        </>
      )}
      <TabOrderShareModal
        handleModalClose={() => setIsShowingTabOrderShareModal(false)}
        modalOpen={isShowingTabOrderShareModal}
      />
    </Layout>
  );
};

Menu.propTypes = {
  floodlightPageLoadEvent: floodlightPageLoadEventPropTypes,
  metaContent: PropTypes.shape(seoPropTypes),
};

Menu.defaultProps = {
  floodlightPageLoadEvent: undefined,
  metaContent: seoPropDefaults,
};

export const getStaticProps = withAppProps(async () => {
  const data = await fetchCMSContent({
    uri: 'menu',
    slug: 'menu',
  });

  const page = data.id ? data : null;

  return {
    props: { ...page, generatedAt: new Date().toISOString() },
    revalidate: 60 * 60, // 1 hour
  };
});

export default observer(Menu);
