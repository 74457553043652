import { Box, Flex } from '@components/grid';
import { Icon } from '@components/icons';
import { Label, P } from '@components/typography';
import { colors, ANALYTICS_EVENTS } from '@nandosaus/constants';
import { useRootStore } from '@nandosaus/state-management';
import { orderItemPropTypes } from '@prop-types/order-item';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import React, { useCallback, useState } from 'react';
import styled from 'styled-components';

import { useFeature } from '@hooks/use-feature';
import { TextPill } from '@components/text-pill';
import { analytics } from '../../analytics';

const FlexWithDivider = styled(Flex)`
  :not(:last-child) {
    border-bottom: 1px solid ${({ theme }) => theme.colors.grey100};
  }

  cursor: ${({ disableOnClick }) => (disableOnClick ? 'default' : 'pointer')};
`;

const Pricing = ({ formattedPrice }) => {
  return (
    <>
      <Flex alignItems="stretch" flexShrink={0} justifyContent="right">
        <P variant={3}>{formattedPrice}</P>
      </Flex>
    </>
  );
};

Pricing.propTypes = {
  formattedPrice: PropTypes.string.isRequired,
};

const QuantityIndicator = ({ quantity }) => {
  return (
    <Flex
      alignItems="center"
      borderColor="grey300"
      borderStyle="solid"
      borderWidth="1px"
      height="1.5rem"
      justifyContent="center"
      mr={{ _: '1rem', xl: '0.5rem' }}
      minWidth="1.5rem"
    >
      <P variant={3}>{quantity}</P>
    </Flex>
  );
};

QuantityIndicator.propTypes = { quantity: PropTypes.number.isRequired };

const RedOnHoverIcon = styled(Icon)`
  transition: fill 0.2s linear;

  :hover {
    fill: ${({ theme }) => theme.colors.neonRed};
  }
`;

const DeleteItemButton = ({ onClick }) => {
  return (
    <Flex as="button" aria-label="Remove this item" alignItems="flex-end" justifyContent="flex-end" onClick={onClick}>
      <RedOnHoverIcon role="button" name="trash" fill={colors.grey400} height={{ _: '18px', lg: '24px' }} />
    </Flex>
  );
};

DeleteItemButton.propTypes = { onClick: PropTypes.func.isRequired };

const UnderlineOnHoverLabel = styled(Label)`
  text-decoration: ${({ hovering, disableOnClick }) => (hovering && !disableOnClick ? 'underline' : 'none')};
`;

const OrderItemListItem = ({ orderItem, setProductDetailModalOpen, disableOnClick }) => {
  const cartRemovalEnabled = useFeature('remove-from-cart');
  const [hovering, setHovering] = useState(false);

  const { ProductDetailsState, CartStore } = useRootStore();
  const { quantity, product, formattedKilojoules, totals, isUpsellItem } = orderItem;

  const removeOrderItem = useCallback(() => {
    CartStore.removeOrderItem(orderItem);
  }, [CartStore, orderItem]);

  const editOrderItem = useCallback(() => {
    analytics.track(ANALYTICS_EVENTS.PRODUCT_UPDATE_CLICKED, { product: orderItem.product.name });
    ProductDetailsState.editOrderItem({ orderItem });
    setProductDetailModalOpen(true);
  }, [ProductDetailsState, orderItem, setProductDetailModalOpen]);

  return (
    <FlexWithDivider py={{ _: 2, lg: 1 }} flexDirection="column" key={orderItem.id} disableOnClick={disableOnClick}>
      <Flex width={3 / 3} justifyContent="space-between">
        <Flex
          onClick={() => disableOnClick || editOrderItem()}
          onMouseOver={() => setHovering(true)}
          onMouseOut={() => setHovering(false)}
          width={2 / 3}
        >
          <QuantityIndicator quantity={quantity} />
          <Box>
            <UnderlineOnHoverLabel hovering={hovering} disableOnClick={disableOnClick}>
              {product.longName}
            </UnderlineOnHoverLabel>
            {product.choices.map(choice => {
              const values = orderItem.choices.get(choice.name) || [];
              return values.map(value => (
                <P variant={3} color="greyPrimary" mt="0.25rem" key={value.option.name}>
                  {value.option.name}
                </P>
              ));
            })}
            {formattedKilojoules && (
              <P variant={4} mt="0.25rem" color="greyPrimary">
                {formattedKilojoules}
              </P>
            )}
            {isUpsellItem && (
              <Box mt="0.25rem">
                <TextPill
                  text="ADDED"
                  borderRadius="5px"
                  color={colors.black}
                  backgroundColor={colors.neonTeal400}
                  height={{ _: '20px', lg: '22px' }}
                  width={{ _: '54px', lg: '60px' }}
                />
              </Box>
            )}
          </Box>
        </Flex>

        <Flex
          position="relative"
          textAlign="right"
          width={1 / 3}
          justifyContent="space-between"
          alignItems="stretch"
          flexDirection="column"
        >
          <Pricing formattedPrice={totals.formattedPrice} />
          {cartRemovalEnabled && !disableOnClick && <DeleteItemButton onClick={removeOrderItem} />}
        </Flex>
      </Flex>
    </FlexWithDivider>
  );
};

OrderItemListItem.propTypes = {
  orderItem: orderItemPropTypes.isRequired,
  setProductDetailModalOpen: PropTypes.func.isRequired,
  disableOnClick: PropTypes.bool,
};

OrderItemListItem.defaultProps = { disableOnClick: false };

const ObservedOrderItemListItem = observer(OrderItemListItem);

export { ObservedOrderItemListItem as OrderItemListItem };
