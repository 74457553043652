import { ANALYTICS_EVENTS, colors } from '@nandosaus/constants';
import { rgba } from 'polished';
import { useState } from 'react';
import PropTypes from 'prop-types';
import styled, { css, keyframes } from 'styled-components';

import { analytics } from '../../analytics';
import { breakpoints } from '../../constants';
import { Button } from '../button';
import { Container, Col, Box, Flex, Row } from '../grid';
import { Icon } from '../icons';

const SearchBar = ({ onSearchChange, searchValue, ...rest }) => {
  const [showSearch, setShowSearch] = useState(false);

  return (
    <StyledContainer showSearch={showSearch}>
      <Row>
        <StyledButtonCol display={showSearch ? 'none' : 'block'}>
          <ShowSearchButton
            p="0"
            display="flex"
            alignItems="center"
            onClick={() => setShowSearch(true)}
            aria-label="show search field"
          >
            <Icon name="search" fill="greyPrimary" height={{ _: '20px', lg: '24px' }} />
            <Box ml=".5rem">Can&apos;t find what you&apos;re looking for?</Box>
          </ShowSearchButton>
        </StyledButtonCol>
      </Row>
      <Row>
        <StyledSearchCol display={showSearch ? 'flex' : 'none'} showSearch={showSearch} {...rest}>
          <TextField>
            <Box ml={{ _: '.5rem', lg: '.75rem' }} display="flex" alignItems="center">
              <Icon name="search" fill="greyPrimary" height={{ _: '20px', lg: '24px' }} />
            </Box>
            <TextInput
              name="searchField"
              placeholder="Search our menu e.g. burger, salad..."
              value={searchValue}
              onChange={event => {
                const { value } = event.target;
                onSearchChange(value);
              }}
              aria-label="clear search results"
            />
            {searchValue && (
              <StyledButton
                variant="tertiary"
                onClick={() => {
                  onSearchChange('');
                  analytics.track(ANALYTICS_EVENTS.MENU_SEARCH_CLEARED);
                }}
              >
                Clear
              </StyledButton>
            )}
          </TextField>
          <HideSearchButton
            p=".75rem"
            display="flex"
            alignItems="center"
            onClick={() => {
              setShowSearch(false);
              onSearchChange('');
              analytics.track(ANALYTICS_EVENTS.MENU_SEARCH_CLEARED);
            }}
            aria-label="Hide search field"
          >
            <Icon name="cross" fill="greyPrimary" height={{ _: '20px', lg: '24px' }} />
          </HideSearchButton>
        </StyledSearchCol>
      </Row>
    </StyledContainer>
  );
};

const StyledContainer = styled(Container)`
  @media (max-width: ${breakpoints.lg}) {
    ${({ showSearch }) =>
      showSearch &&
      `
      max-width: none;
      padding: 0;
    `}
  }
`;

const slideDown = keyframes`
    from {
      opacity: 0;
      transform: translateY(-100px);
    }
    to {
      opacity: 1;
      transform: translateY(0px);
    }
`;

const growRight = keyframes`
  from {
    opacity: 0;
    transform: translateX(-50px);
  }
  to {
    opacity: 1;
    transform: translateX(0px);
  }
`;

const SearchColStyles = css`
  align-items: center;
  overflow: hidden;
  padding-top: 1rem;
  padding-bottom: 1rem;
  margin-left: 0.5rem;
  margin-right: 0.5rem;

  @media (min-width: ${breakpoints.md}) {
    top: 120px;
  }

  @media (min-width: ${breakpoints.lg}) {
    background-color: transparent;
    justify-content: flex-start;
    position: relative;
    top: 0px;
  }
`;

const StyledButtonCol = styled(Col)`
  ${SearchColStyles}
  animation: ${growRight} ease 0.4s forwards;
  transform-origin: left;
  border-bottom: 1.5px solid transparent;

  @media (min-width: ${breakpoints.md}) {
    border-bottom: 1px solid transparent;
  }

  @media (min-width: ${breakpoints.lg}) {
    border-bottom: none;
  }

  @media (min-width: ${breakpoints.xxl}) {
    border-bottom: 1.5px solid transparent;
  }
`;

const StyledSearchCol = styled(Col)`
  ${SearchColStyles}

  animation: ${slideDown} ease 0.4s forwards;
  background-color: ${({ theme }) => theme.colors.white};
  border-bottom: 1px solid ${colors.grey100};
  position: sticky;
  top: 100px;
  transform-origin: top;
  transition: transform 0.2s ease-in;
  wrap: nowrap;
  z-index: 19;

  padding-left: 1.5rem;
  padding-right:  1.5rem;

  @media (min-width: ${breakpoints.md}) {
    padding-left: 2rem;
    padding-right: 2rem;
  }

  @media (min-width: ${breakpoints.lg}) {
    border-bottom: none;
    transform: translateY(0);
    padding-left: 0;
    padding-right: 0;
  }
`;

const ShowHideSearchButton = css`
  border: none;
  background-color: transparent;
  color: ${({ theme }) => theme.colors.grey500};

  div {
    text-transform: none;
  }
`;

const ShowSearchButton = styled(Button)`
  ${ShowHideSearchButton}
  text-decoration: underline;
`;

const HideSearchButton = styled(Button)`
  ${ShowHideSearchButton}
`;

const TextField = styled(Flex)`
  align-items: center;
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 4px;
  border: 1px solid ${({ theme }) => theme.colors.grey300};
  width: 100%;

  :focus-within {
    border-color: ${({ theme }) => theme.colors.info400};
    box-shadow: 0px 0px 6px 0px ${({ theme }) => rgba(theme.colors.info400, 0.25)};
  }
`;

const TextInput = styled.input`
  background: transparent;
  border: none;
  color: ${({ theme }) => theme.colors.black};
  font-size: 16px;
  padding: 1rem 0.5rem;
  width: 100%;

  ::placeholder {
    color: ${({ theme }) => theme.colors.grey300};
  }

  @media (min-width: ${breakpoints.xl}) {
    padding: 0.75rem 0.5rem;
  }
`;

const StyledButton = styled(Button)`
  border: none;
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
  padding: 0 1rem;
  height: unset;

  span {
    color: ${({ theme }) => theme.colors.grey500};
    text-transform: none;
  }
`;

SearchBar.propTypes = {
  onSearchChange: PropTypes.func.isRequired,
  searchValue: PropTypes.string.isRequired,
};

export { SearchBar };
