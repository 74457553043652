import { head, includes, isEmpty, keysIn, map, mapValues } from 'lodash';
import { observer } from 'mobx-react';
import { useRootStore } from '@nandosaus/state-management';
import React, { useCallback, useEffect, useRef, useState } from 'react';

import { Box } from '../grid';
import { ChoicePicker } from '../product-detail-modal/choice-picker';
import { ProductSizePicker } from '../product-detail-modal/product-size-picker';

const MealChoices = () => {
  const { ProductDetailsState } = useRootStore();
  const { mealProduct, orderItem } = ProductDetailsState;
  const highlightMissingRequiredChoices = false;
  const selectedOptions = mapValues(orderItem.groupedMealOptions, options => map(options, 'id'));

  const defaultOpenedState = { choice: null, option: null };
  const [openedState, setOpenedState] = useState(defaultOpenedState); // choice could be calculated from selected option, but it's tricky. easier to just keep it in state.

  const choiceIdRefs = useRef({});

  const missingRequiredChoices = mealProduct.missingRequiredMealChoices(keysIn(selectedOptions));

  const scrollToFirstMissingRequiredChoiceNode = useCallback(() => {
    if (isEmpty(missingRequiredChoices)) {
      return;
    }

    const firstMissingRequiredChoiceNode = choiceIdRefs.current[missingRequiredChoices[0]];
    if (firstMissingRequiredChoiceNode) {
      firstMissingRequiredChoiceNode.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }, [missingRequiredChoices]);

  const mealChoiceSizes = openedState.option ? openedState.option.otherSizes : [];
  const isSizePickerDrawerOpen = !!openedState.option;

  useEffect(() => {
    scrollToFirstMissingRequiredChoiceNode();
  }, [missingRequiredChoices, scrollToFirstMissingRequiredChoiceNode]);

  return (
    <>
      {orderItem.product?.mealChoices?.map((choice, index) => {
        const selectedOptionIdsMappedToGroupNamesWherePossible = choice.getOptionIdsToGroupNames(
          selectedOptions[choice.id]
        );
        return (
          <Box
            key={choice.id}
            id={choice.id}
            ref={node => {
              choiceIdRefs.current[choice.id] = node;
            }}
          >
            <ChoicePicker
              choice={choice}
              index={index}
              isChoiceInvalid={highlightMissingRequiredChoices && includes(missingRequiredChoices, choice.id)}
              onClick={optionId => {
                const mealPickerOption = choice.optionsForMealPicker.find(
                  option => option.groupName === optionId || option.value === optionId
                );
                const shouldOpenSizePicker = !!mealPickerOption?.groupName;

                if (shouldOpenSizePicker) {
                  setOpenedState({ option: mealPickerOption, choice });
                } else {
                  ProductDetailsState.updateChoiceAndMigrate(choice.name, optionId);
                }
              }}
              isMealPicker
              selectedOptions={selectedOptionIdsMappedToGroupNamesWherePossible}
            />
          </Box>
        );
      })}

      {isSizePickerDrawerOpen ? (
        <>
          <Box
            bg="rgba(0, 0, 0, 0.3)"
            position="absolute"
            height="100%"
            width="100%"
            zIndex={2}
            top={0}
            onClick={() => {
              setOpenedState(defaultOpenedState);
            }}
          />

          <Box
            pt={2}
            bg="background"
            position="absolute"
            bottom={0}
            width="100%"
            zIndex={3}
            backgroundImage="url(/static/images/background.svg)"
          >
            <ProductSizePicker
              sizes={map(mealChoiceSizes, mealChoiceSize => ({
                size: mealChoiceSize.label,
                isAvailable: !mealChoiceSize.disabled,
                formattedKilojoules: mealChoiceSize.kilojoulesValue,
                id: mealChoiceSize.value,
                isEligibleForDeliveryDiscount: mealChoiceSize.isEligibleForDeliveryDiscount,
                prices: { formattedPrice: isEmpty(mealChoiceSize.price) ? null : `+ ${mealChoiceSize.price}` },
              }))}
              onChange={selectedOptionId => {
                ProductDetailsState.updateChoiceAndMigrate(openedState.choice.name, selectedOptionId);
                setOpenedState(defaultOpenedState);
              }}
              value={head(selectedOptions[openedState.choice.id])}
              showDisclaimer
            />
          </Box>
        </>
      ) : null}
    </>
  );
};

MealChoices.propTypes = {};

const ConnectedMealChoices = observer(MealChoices);

export { ConnectedMealChoices as MealChoices };
