import { Card } from '@components/card/card';
import { CardWrapper } from '@components/card/card-wrapper';
import { Box } from '@components/grid';
import { SkeletonLoader } from '@components/skeleton-loader';
import { P } from '@components/typography';
import PropTypes from 'prop-types';
import { CARD_ASPECT_RATIOS, getCardImageHeight } from './menu-item-card';

const MenuItemCardSkeleton = ({ shouldKeepVertical, variant, role, tall }) => {
  return (
    <CardWrapper
      role={role}
      margin="8px"
      minHeight={{ xs: '100px', md: '150px' }}
      minWidth={{ xs: '150px', md: '200px' }}
    >
      <Card
        flexWrap={{ xs: 'no-wrap' }}
        flexDirection={{ xs: shouldKeepVertical ? 'column' : 'row', md: 'column' }}
        contentWidth={{ xs: shouldKeepVertical ? 1 : 8 / 12, md: 1 }}
        backgroundColor={variant}
        contentHeight="100%"
        justifyContent="space-between"
        padding={null}
        borderRadius={12}
        rounded
        renderInsteadOfImage={
          <Box height={getCardImageHeight(tall, shouldKeepVertical)}>
            <SkeletonLoader height="100%" width="100%" />
          </Box>
        }
      >
        <Box aspectRatio={tall ? CARD_ASPECT_RATIOS.tall : CARD_ASPECT_RATIOS.square} position="relative" height="100%">
          <Box p={{ xs: shouldKeepVertical ? '0.75rem' : 1, xxl: '0.75rem' }}>
            <P mt="0.5rem" variant={3} color="greyPrimary">
              <SkeletonLoader />
            </P>
          </Box>
        </Box>
      </Card>
    </CardWrapper>
  );
};

MenuItemCardSkeleton.propTypes = {
  shouldKeepVertical: PropTypes.bool,
  variant: PropTypes.string,
  role: PropTypes.string,
  tall: PropTypes.bool,
};

MenuItemCardSkeleton.defaultProps = {
  shouldKeepVertical: true,
  variant: 'transparent',
  role: '',
  tall: false,
};

export { MenuItemCardSkeleton };
