import { compact, debounce, head, isEmpty, map, reduce } from 'lodash';
import { DIETARY, ANALYTICS_EVENTS } from '@nandosaus/constants';
import { useFeature } from '@hooks/use-feature';
import { useState } from 'react';
import Fuse from 'fuse.js';

import { analytics } from '../../analytics';
import { logger } from '../../utils/logger';

const SEARCH_SCORE_THRESHOLD = 0.2; // max fuzzy score for search result to be included (larger score is less relevant)
const MAX_RESULTS = 12; // max number of search results to display
const SEARCH_MIN_MATCH_CHAR_LENGTH = 3; // min char length to start searching

const debounceAnalyticsTrack = debounce(analytics.track, 1000); // debounce analytics to reduce triggering for incomplete search terms
const debounceLoggerInfo = debounce(logger.info, 1000);

const FUSE_CONFIG = {
  ignoreLocation: true, // ignore search term position in searched text
  findAllMatches: false, // continue searching after first match
  includeScore: true, // return fuzzy score in results data
  fieldNormWeight: 0.02, // reduce importance of field length in fuzzy score
  keys: [
    { name: 'product.name', weight: 22 },
    { name: 'synonyms', weight: 22 },
    { name: 'category', weight: 5 },
    { name: 'dietaryPreferences', weight: 1 },
    { name: 'product.choices.options.name', weight: 1 },
  ], // keys in data in which to search for the search term
  minMatchCharLength: SEARCH_MIN_MATCH_CHAR_LENGTH,
};

export const getProducts = ({ categories, searchSynonyms }) =>
  categories.flatMap(category =>
    category.products.map(product => {
      const dietaryPreferences = reduce(
        product.suitability,
        (acc, value, dietary) => {
          if (value.range.includes(DIETARY.PARTIAL) || value.range.includes(DIETARY.YES)) {
            return [...acc, dietary];
          }
          return acc;
        },
        []
      );

      // check for keywords and add synonyms to product data
      let productSynonyms = {};
      if (!isEmpty(searchSynonyms)) {
        productSynonyms = compact(
          map(searchSynonyms, (synonym, key) => {
            const regex = new RegExp(`${key}`, 'i');
            return head(product.name.match(regex)) ? synonym : undefined;
          })
        );
      }

      return { product, category: category.name, dietaryPreferences, synonyms: productSynonyms };
    })
  );

const useSearch = ({ categories }) => {
  const { synonyms } = useFeature('search');
  const [searchValue, setSearchValue] = useState('');

  const hasSearchValue = searchValue.length >= SEARCH_MIN_MATCH_CHAR_LENGTH;
  const products = getProducts({ categories, searchSynonyms: synonyms });

  const fuse = new Fuse(products, FUSE_CONFIG);
  const searchProducts = fuse
    .search(searchValue)
    .filter(({ score }) => score < SEARCH_SCORE_THRESHOLD)
    .slice(0, MAX_RESULTS)
    .map(({ item }) => item.product);

  const searchCategory = [
    {
      name: 'Search Results',
      handle: 'search-results',
      description: '',
      products: searchProducts,
    },
  ];

  if (hasSearchValue) {
    debounceAnalyticsTrack(ANALYTICS_EVENTS.MENU_SEARCHED, {
      search_term: searchValue,
      results_found: searchProducts.length,
    });
    debounceLoggerInfo(`New search`, {
      search_term: searchValue,
      results_found: searchProducts.length,
    });
  }

  return {
    categoriesWithSearch: hasSearchValue ? searchCategory : categories,
    searchValue,
    setSearchValue,
    hasSearchValue,
  };
};

export { useSearch };
